<template>
  <div>
    <div class="route-wrapper">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">
            Route Payments Intelligently
          </div>
          <div class="content margin-top-15">
            <div>Declined transactions mean lost revenue for you and a negative experience for your customers. But driving higher success rates is difficult to manage by yourself. How can you optimize success rates like the world’s most demanding digital businesses?</div>
            <div class="margin-top-20">moozumi lets you intelligently route transactions to boost authorization rates. With our deep expertise and data from billions of transactions, moozumi’s suite of smart routing tools can optimize your success rates, lower costs, and provide a better experience for your buyers.</div>
          </div>
        </div>
        <img src="../../assets/images/route_payment_intelligently/p1.png" />
      </div>
    </div>
    <div class="content-list">
      <div class="inner">
        <div>
          <MoozumiCard :icon="cards[0].icon" :title="cards[0].title" :content="cards[0].content"></MoozumiCard>
          <MoozumiCard :icon="cards[1].icon" :title="cards[1].title" :content="cards[1].content" class="margin-top-100"></MoozumiCard>
        </div>
        <div>
          <MoozumiCard :icon="cards[2].icon" :title="cards[2].title" :content="cards[2].content" class="mobile-style"></MoozumiCard>
        </div>
      </div>
    </div>
    <ConnectwiththeexpertsinPaymentOrchestration></ConnectwiththeexpertsinPaymentOrchestration>
  </div>
</template>
<script>
import ConnectwiththeexpertsinPaymentOrchestration from '../../components/ConnectwiththeexpertsinPaymentOrchestration.vue'
import MoozumiCard from '../../components/MoozumiCard.vue'

export default {
  components: {
    ConnectwiththeexpertsinPaymentOrchestration,
    MoozumiCard
  },
  data () {
    return {
      cards: [
        {
          icon: require('../../assets/images/route_payment_intelligently/icon1.png'),
          title: 'Leverage Rules to Intelligently Route Transactions',
          content: [
            'Your payment strategy is unique to your business. So, how you optimize transactions should be customized to your particular needs too. But managing the complex logic to route transactions can be a challenge that drains your development resources.',
            'With moozumi, you control how authorization requests are reviewed or routed for approval. You can route for authorization approval based on specific criteria, prevent potentially problematic transactions from being submitted, and even retry transactions on an alternative gateway.'
          ]
        },
        {
          icon: require('../../assets/images/route_payment_intelligently/icon3.png'),
          title: 'Transaction Retry to Keep Business Moving',
          content: [
            'When a transaction fails you lose more than the immediate revenue. You also damage the customer experience and risk future business from a frustrated consumer.',
            'Payments Orchestration enables you to redirect a failed transaction to a different provider. That not only increases the chances of a successful transaction, but keeps costs in check and enhances the overall experience.'
          ]
        },
        {
          icon: require('../../assets/images/route_payment_intelligently/icon2.png'),
          title: 'Leverage Our Immense Data Set to Optimize Transactions',
          content: [
            'Optimized payment routing depends on rich data. How do you access and interpret a global cross-industry, cross-provider data set to make intelligent routing decisions?',
            'Leverage moozumi’s insight into tens of millions of transactions per month, across a variety of countries and industries, to route transactions. That helps you to maximize authorizations and revenue.'
          ]
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/variables.scss';

.route-wrapper {
  padding: 120px 0 80px;
  background: url(../../assets/images/route_payment_intelligently/banner.png);
  background-size: cover;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .left-wrapper {
      width: 767px;
      max-width: 100%;
      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: $primary-color1;
        letter-spacing: 0;
        text-align: left;
        line-height: 68px;
      }
      .content {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: $content-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
      }
    }
    img {
      width: 353px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.content-list {
  background: #F4F8FF;
  padding: 120px 0;
  .inner {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &>div {
      width: 575px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 1200px){
  .route-wrapper {
    .left-wrapper {
      padding: 0 15px;
    }
    img {
      margin-top: 20px;
    }
  }
  .content-list {
    .inner {
      &>div {
        padding: 0 15px;
      }
      .moozumi-card {
        &.mobile-style {
          margin-top: 100px;
        }
      }
    }
  }
}
</style>
